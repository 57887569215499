// https://vuex.vuejs.org/en/mutations.html

export default {
  auth_success (state, { token, username, is_root }) {
    state.token = token
    state.username = username
    state.is_root = is_root
  },
  logout (state) {
    state.username = ''
    state.token = ''
    state.is_root = false
  },
}
