// https://vuex.vuejs.org/en/actions.html
import axios from 'axios'
import router from '@/router'

// The login action passes vuex commit helper that we will use to trigger mutations.
export default {
  login ({ commit }, userData) {
    return new Promise((resolve, reject) => {
      axios.post('/Users/Login', { username: userData.username, password: userData.password })
        .then(response => {
          if(typeof response.data.token !== 'undefined'){
            const username = response.data.username;
            const token = response.data.token;
            const is_root = response.data.is_root;

            localStorage.setItem('token', token);
            localStorage.setItem('username', username);
            localStorage.setItem('is_root',JSON.stringify(is_root));

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
            
            commit('auth_success', { username, token, is_root })
            resolve('auth_success')
          }
        })
        .catch(err => {
          console.log('login error')
          localStorage.removeItem('token')
          localStorage.removeItem('username')
          localStorage.removeItem('is_root')
          reject(err)

        })
    })
  },
  logout ({ commit }) {
    return new Promise((resolve) => {
      commit('logout')
      localStorage.removeItem('token')
      localStorage.removeItem('username')
      localStorage.removeItem('is_root')
      delete axios.defaults.headers.common['Authorization']
      router.push("/")
      resolve()
    })
  },
  refreshtoken ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/users/refresh')
      .then(response => {
        const token = response.data.token
        localStorage.setItem('token', token)
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
        commit('auth_success', { token })
        resolve()
      })
      .catch(error => {
        console.log('refresh token error')
        console.log(error)
        commit('logout')
        localStorage.removeItem('token')
        localStorage.removeItem('username')
        localStorage.removeItem('is_root')
        delete axios.defaults.headers.common['Authorization']  
        router.push("/")
        reject()
      })
    })
  }
}
