import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import axios from 'axios'
import VueLayers from 'vuelayers'
import 'vuelayers/dist/vuelayers.css' // needs css-loader

Vue.prototype.$http = axios

// Sets the default url used by all of this axios instance's requests
axios.defaults.baseURL = "/api";
axios.defaults.headers.get['Accept'] = 'application/json'

const token = localStorage.getItem('token')
if (token) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

axios.interceptors.response.use(function (response) {
  return response;
}, async function (error) { 
  const originalRequest = error.config;
  if (401 === error.response.status && !originalRequest._retry) {
    await store.dispatch('refreshtoken');
    originalRequest._retry = true;
    originalRequest.headers['Authorization'] = 'Bearer ' + store.state.token;
    originalRequest.baseURL = undefined;
    return Promise.resolve(await axios(originalRequest));
  }
  else if(403 === error.response.status) {
    store.dispatch('logout');
    router.go();
    return Promise.reject(error);
  } else {
      return Promise.reject(error);
  }
});

Vue.config.productionTip = false

Vue.use(VueLayers)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
